// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-honors-js": () => import("./../../../src/pages/honors.js" /* webpackChunkName: "component---src-pages-honors-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presentations-js": () => import("./../../../src/pages/presentations.js" /* webpackChunkName: "component---src-pages-presentations-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-templates-publications-js": () => import("./../../../src/templates/publications.js" /* webpackChunkName: "component---src-templates-publications-js" */)
}

